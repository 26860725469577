import "core-js/modules/es6.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ProfileRewardsNotice',
  computed: {
    rewards: function rewards() {
      return this.$store.getters['rewards/rewards'];
    },
    rewardsAmount: function rewardsAmount() {
      var _this = this;

      return Object.keys(this.rewards).reduce(function (acc, key) {
        return acc + (_this.rewards[key].available && !_this.rewards[key].completed ? _this.rewards[key].coins : 0);
      }, 0);
    }
  },
  methods: {
    showProfileRewardsModal: function showProfileRewardsModal() {
      this.$store.dispatch('modals/setRewardsModal', {
        isOpen: true
      });
      this.$store.gaModal.openReward({
        event: 'rewards',
        step: 'info',
        location: 'header'
      });
    }
  }
};